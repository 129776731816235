@use '../global';

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;

  h1 {
    color: white;
    font-size: 45px;
    margin: 10px 0;
  }

  h2 {
    color: #a6a6a6;
    font-size: 25px;
    font-weight: normal;
    max-width: 450px;
    margin: 10px 0;
  }

  button {
    margin: 10px 0;
    border: none;
    padding: 8px 18px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    color: black;

    &:hover {
      cursor: pointer;
      background-color: rgb(207, 207, 207);
    }
  }
}
