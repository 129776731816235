@use '../global';

#about-us {
  margin: 50px 0;
  .embrace-group {
    display: flex;
    text-align: left;

    h3 {
      display: flex;
      width: 1400px;
      margin: 0 30px 0 0;
      align-items: center;
    }
  }

  .leading-text {
    display: flex;
    position: relative;
    width: 450px;
    margin: 70px auto 70px auto;

    h3 {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: white;
      font-weight: normal;
      font-size: 30px;
      margin: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .leading-columns {
    display: flex;
    text-align: center;

    div {
      margin: 0 30px;

      h4 {
        font-size: 25px;
        font-weight: normal;
        color: white;
        text-decoration: underline;
        margin: 0;

        &.blue-underline {
          text-decoration-color: #39c4ff;
        }
        &.pink-underline {
          text-decoration-color: #ff3c82;
        }
        &.yellow-underline {
          text-decoration-color: #faff00;
        }
      }
    }
  }
}
