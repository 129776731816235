@use '../global';

#our-partners {
  background-color: black;
  width: 100vw !important;
  max-width: 100vw !important;
  box-shadow: 0 0 20px rgba(156, 218, 238, 0.5);

  .main-container {
    width: 90%;
    max-width: 1200px;

    .pt-container {
      background-color: global.$background-lightgrey;
      border-radius: 15px;
      padding: 25px;
      position: relative;

      .logo {
        height: 90px;
        margin-bottom: 20px;
      }
      .screenshots {
        width: 100%;
      }
    }
  }
}
