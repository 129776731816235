$background-grey: #1e1e1e;
$background-lightgrey: #232323;

$theme-accent-lilac: #a79ecd;
$theme-accent-cyan: #9cdaee;
$theme-blue: #3a4b79;
$theme-purple: #443560;
$theme-darkblue: #15162b;

body {
  margin: 0;
  box-sizing: border-box;
  background-color: $background-lightgrey;
  max-width: 100%;
  max-height: 100%;
  font-family: 'Inter', sans-serif;
  color: white;

  header,
  footer {
    width: 100vw;
  }

  section {
    width: 90%;
    max-width: 1200px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h3 {
  font-size: 30px;
  text-align: left;
  width: 100%;
}
