@use '../global';

#features {
  margin: 50px 0;

  .feature-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    list-style: none;

    li {
      flex: 1 1 45%; /* Adjust to make two columns */

      @media (max-width: 600px) {
        flex: 1 1 100%; /* Full width on mobile */
      }
    }

    .item-container {
      display: flex;

      .item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: global.$theme-accent-lilac;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        aspect-ratio: 1/1;
        margin-right: 10px;
        svg {
          width: 100%;
          height: 100%;
          color: black;
          padding: 5px;
        }
        &.blue-accent {
          background-color: global.$theme-accent-cyan;
        }
      }
    }

    .item-text {
      text-align: left;
      h4 {
        margin: 10px 0;
        color: global.$theme-accent-lilac;

        &.blue-accent {
          color: global.$theme-accent-cyan;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
