@use '../global';

header {
  background-color: global.$background-grey;
  box-shadow: 0 5px 20px rgba(255, 255, 255, 0.25);
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    height: 65%;
    display: flex;
    margin: 0 15px;
  }

  nav {
    margin: 0 15px;
    a {
      color: white;
      text-decoration: none;
      font-size: 14px;
      margin: 0 15px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
