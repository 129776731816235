@use '../global';

#use-cases {
  margin: 50px 0;

  .card {
    display: flex;
    background-color: #141414;
    border: 3px solid global.$theme-accent-lilac;
    border-radius: 15px;
    padding: 10px;
    width: 420px;

    img {
      width: 180px;
      height: 300px;
      object-fit: cover;
      border-radius: 15px;
      margin-right: 15px;
    }
    div {
      text-align: left;

      button {
        margin: 0 auto;
        border: none;
        padding: 8px 18px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.25);
        font-size: 15px;
        font-weight: 600;
        background-color: white;
        color: black;

        &:hover {
          cursor: pointer;
          background-color: rgb(207, 207, 207);
        }
      }
    }
  }
}
